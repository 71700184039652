<template>
  <div class="insure">
    <div class="applicantContent">
      <!-- 保险期间 -->
      <div class="applicant-title">
        <div class="left">
          <p class="titleNum">1</p>
          <p class="titleName">保险期间</p>
        </div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">起保日期</div>
          <p
            class="uni-input"
            :style="formData.startTime ? 'color:#333;' : 'color:#999;'"
            @click="changeDate('start')"
            style="color: #999"
          >
            {{ formData.startTime ? formData.startTime : "点击选择" }}
          </p>
          <img
            style="width: 18px"
            src="../assets/detail/date-icon.png"
            alt=""
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">终保日期</div>
          <p
            class="uni-input"
            :style="formData.endTime ? 'color:#333;' : 'color:#999;'"
            style="color: #999"
          >
            {{ formData.endTime ? formData.endTime : "请先选择起保日期" }}
          </p>
          <img
            style="width: 18px"
            src="../assets/detail/date-icon.png"
            alt=""
          />
        </div>
      </div>
      <!-- 投保人模块 -->
      <div class="applicant-title">
        <div class="left">
          <p class="titleNum">2</p>
          <p class="titleName">投保人信息</p>
        </div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">投保人姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            :onkeyup="
              (formData.holderName = formData.holderName.replace(/\s+/g, ''))
            "
            maxlength="50"
            v-model="formData.holderName"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">统一信用代码</div>
          <input
            class="uni-input"
            placeholder="请输入统一信用代码"
            maxlength="18"
            :onkeyup="
              (formData.holderUniteCode = formData.holderUniteCode.replace(
                /\s+/g,
                ''
              ))
            "
            v-model="formData.holderUniteCode"
          />
        </div>
        <div class="uni-form-item" @touchstart.prevent="changeCity('holder')">
          <div class="tit">地址</div>
          <p
            class="uni-input"
            :style="holderCityName ? 'color: #333' : 'color: #999'"
          >
            {{ holderCityName ? holderCityName : "点击选择" }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
        <div class="uni-form-item">
          <div class="tit">详细地址</div>
          <input
            class="uni-input"
            placeholder="请输入详细地址"
            maxlength="100"
            :onkeyup="(holderAddress = holderAddress.replace(/\s+/g, ''))"
            v-model="holderAddress"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">联系人</div>
          <input
            class="uni-input"
            placeholder="点击输入联系人"
            v-model="formData.holderPersonName"
            :onkeyup="
              (formData.holderPersonName = formData.holderPersonName.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="50"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">联系方式</div>
          <input
            type="tel"
            class="uni-input"
            placeholder="点击输入联系方式"
            v-model="formData.holderPhone"
            :onkeyup="
              (formData.holderPhone = formData.holderPhone.replace(/\s+/g, ''))
            "
            maxlength="11"
          />
        </div>

        <div class="uni-form-item">
          <div class="tit">上年末员工总数</div>
          <input
            class="uni-input"
            placeholder="请输入员工总数"
            :onkeyup="
              (formData.holderLastyearEmployeeSum =
                formData.holderLastyearEmployeeSum.replace(/[^\d:]/g, ''))
            "
            v-model="formData.holderLastyearEmployeeSum"
          />
          <p class="unit">人</p>
        </div>
        <div class="uni-form-item">
          <div class="tit">上年度营业收入</div>
          <input
            class="uni-input"
            placeholder="请输入营业收入"
            oninput="value=value.replace(/[^\-?\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            :onkeyup="
              (formData.holderLastyearSaleIncome =
                formData.holderLastyearSaleIncome.replace(/[^\-?\d.]/g, ''))
            "
            v-model="formData.holderLastyearSaleIncome"
          />
          <p class="unit">万元</p>
        </div>
        <div class="uni-form-item">
          <div class="tit">上年末资产总额</div>
          <input
            class="uni-input"
            placeholder="请输入资产总额"
            oninput="value=value.replace(/[^\-?\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            :onkeyup="
              (formData.holderLastyearMoneySum =
                formData.holderLastyearMoneySum.replace(/[^\-?\d.]/g, ''))
            "
            v-model="formData.holderLastyearMoneySum"
          />
          <p class="unit">万元</p>
        </div>
        <div
          class="uni-form-item"
          @touchstart.prevent="changeBusiness('holder')"
        >
          <div class="tit">主营业务类型</div>
          <p
            class="uni-input busines"
            :style="
              formData.holderBusinessMainType ? 'color: #333' : 'color: #999'
            "
          >
            {{
              formData.holderBusinessMainType
                ? formData.holderBusinessMainType
                : "点击选择"
            }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
      </div>
      <!-- 被保人模块 -->
      <div class="applicant-title">
        <div class="left">
          <p class="titleNum">3</p>
          <p class="titleName">被保人信息</p>
        </div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">同投保人</div>
          <p class="uni-input" style="color: #999"></p>
          <div>
            <van-switch
              v-model="isSwitch"
              @change="changeSwitch"
              size="26px"
              active-color="#1B25FE"
            />
          </div>
        </div>
        <div class="uni-form-item">
          <div class="tit">被保人姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            :disabled="isSwitch"
            :onkeyup="
              (formData.insuredName = formData.insuredName.replace(/\s+/g, ''))
            "
            maxlength="50"
            v-model="formData.insuredName"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">统一信用代码</div>
          <input
            class="uni-input"
            placeholder="请输入统一信用代码"
            maxlength="18"
            :disabled="isSwitch"
            :onkeyup="
              (formData.insuredUniteCode = formData.insuredUniteCode.replace(
                /\s+/g,
                ''
              ))
            "
            v-model="formData.insuredUniteCode"
          />
        </div>
        <div class="uni-form-item" v-if="isSwitch">
          <div class="tit">地址</div>
          <p
            class="uni-input"
            :style="insuredCityName ? 'color: #999' : 'color: #999'"
          >
            {{ insuredCityName ? insuredCityName : "点击选择" }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
        <div
          class="uni-form-item"
          v-else
          @touchstart.prevent="changeCity('insured')"
        >
          <div class="tit">地址</div>
          <p
            class="uni-input"
            :style="insuredCityName ? 'color: #333' : 'color: #999'"
          >
            {{ insuredCityName ? insuredCityName : "点击选择" }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
        <div class="uni-form-item">
          <div class="tit">详细地址</div>
          <input
            class="uni-input"
            placeholder="请输入详细地址"
            maxlength="100"
            :disabled="isSwitch"
            :onkeyup="(insuredAddress = insuredAddress.replace(/\s+/g, ''))"
            v-model="insuredAddress"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">联系人</div>
          <input
            :disabled="isSwitch"
            class="uni-input"
            placeholder="点击输入联系人"
            v-model="formData.insuredPersonName"
            :onkeyup="
              (formData.insuredPersonName = formData.insuredPersonName.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="50"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">联系方式</div>
          <input
            type="tel"
            :disabled="isSwitch"
            class="uni-input"
            placeholder="点击输入联系方式"
            v-model="formData.insuredPhone"
            :onkeyup="
              (formData.insuredPhone = formData.insuredPhone.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="11"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">房间数</div>
          <input
            class="uni-input"
            placeholder="点击输入房间数"
            v-model="formData.rooms"
            :onkeyup="(formData.rooms = formData.rooms.replace(/[^\d:]/g, ''))"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">上年末员工总数</div>
          <input
            :disabled="isSwitch"
            class="uni-input"
            placeholder="请输入员工总数"
            :onkeyup="
              (formData.insuredLastyearEmployeeSum =
                formData.insuredLastyearEmployeeSum.replace(/[^\d:]/g, ''))
            "
            v-model="formData.insuredLastyearEmployeeSum"
          />
          <p class="unit">人</p>
        </div>
        <div class="uni-form-item">
          <div class="tit">上年度营业收入</div>
          <input
            :disabled="isSwitch"
            class="uni-input"
            placeholder="请输入营业收入"
            oninput="value=value.replace(/[^\-?\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            :onkeyup="
              (formData.insuredLastyearSaleIncome =
                formData.insuredLastyearSaleIncome.replace(/[^\-?\d.]/g, ''))
            "
            v-model="formData.insuredLastyearSaleIncome"
          />
          <p class="unit">万元</p>
        </div>
        <div class="uni-form-item">
          <div class="tit">上年末资产总额</div>
          <input
            :disabled="isSwitch"
            class="uni-input"
            placeholder="请输入资产总额"
            oninput="value=value.replace(/[^\-?\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            :onkeyup="
              (formData.insuredLastyearMoneySum =
                formData.insuredLastyearMoneySum.replace(/[^\-?\d.]/g, ''))
            "
            v-model="formData.insuredLastyearMoneySum"
          />
          <p class="unit">万元</p>
        </div>
        <div class="uni-form-item" v-if="isSwitch">
          <div class="tit">主营业务类型</div>
          <p
            class="uni-input busines"
            :style="
              formData.insuredBusinessMainType ? 'color: #999' : 'color: #999'
            "
          >
            {{
              formData.insuredBusinessMainType
                ? formData.insuredBusinessMainType
                : "点击选择"
            }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
        <div
          class="uni-form-item"
          v-else
          @touchstart.prevent="changeBusiness('insured')"
        >
          <div class="tit">主营业务类型</div>
          <p
            class="uni-input busines"
            :style="
              formData.insuredBusinessMainType ? 'color: #333' : 'color: #999'
            "
          >
            {{
              formData.insuredBusinessMainType
                ? formData.insuredBusinessMainType
                : "点击选择"
            }}
          </p>
          <img src="../assets/detail/arrowleft-icon.png" alt="" />
        </div>
      </div>
      <!-- 附加险模块 -->
      <div class="applicant-title" @click="changeIsScheme">
        <div class="left">
          <p class="titleNum">4</p>
          <p class="titleName">雇主责任险</p>
        </div>
        <div class="right">
          <img src="../assets/detail/isScheme.png" alt="" v-if="!isScheme" />
          <img src="../assets/detail/isScheme-active.png" alt="" v-else />
        </div>
      </div>
      <div class="schemeList" v-if="isScheme">
        <div
          class="item"
          :class="schemeActive == index ? 'active' : 'no-active'"
          v-for="(item, index) in schemeList"
          :key="index"
          @click="changeScheme(item, index)"
        >
          <p>{{ item.name }}</p>
          <p>{{ item.value }}/年/人</p>
        </div>
      </div>
      <div class="employee" v-if="isScheme" @click="employee">
        <p>填写雇员清单</p>
      </div>
      <!-- 提交模块 -->
      <!-- <SubmitTip ref="submitTip" @submit="apply"  :premium="productDetail.premium" /> -->
      <div class="footer">
        <div class="prcieContent">
          <div class="price">
            保费：¥
            <span>{{ formData.price }}</span>
          </div>
          <div class="btn" @click="submit">提交</div>
        </div>
      </div>
    </div>
    <!-- 日期组件 -->
    <DatePiker
      :isShow="isDate"
      :typeDate="typeDate"
      :checkDate="checkDate"
      @cancelDate="cancelDate"
      @callbackDate="callbackCertDate"
    />
    <!-- 城市组件 -->
    <CityPiker
      :isShow="isCity"
      :typeCity="typeCity"
      :cityIdHolder="cityIdHolder"
      :cityIdInsured="cityIdInsured"
      @onClose="closeCity"
      @onFinish="finishCity"
    />
    <!-- 主营业务类型组件 -->
    <PopupPiker
      :isShow="isBusiness"
      :typeBusiness="typeBusiness"
      :holderBusinessMainType="formData.holderBusinessMainType"
      :insuredBusinessMainType="formData.insuredBusinessMainType"
      @onClose="closeBusiness"
      @onFinsh="finshBusiness"
    />
    <!-- 成功提示 -->
    <van-popup v-model="showPopup" :close-on-click-overlay="false">
      <div class="success">
        <img src="../assets/detail/success.png" alt="" />
        <p class="tit">恭喜您提交成功</p>
        <p class="btn" @click="back">我知道了</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
import DatePiker from "../components/datePiker.vue";
import CityPiker from "../components/cityPiker.vue";
import PopupPiker from "../components/popupPiker.vue";
import { insure } from "../utils/api";
import { validPhone } from "../utils/validate";
export default {
  components: {
    DatePiker,
    CityPiker,
    PopupPiker,
  },
  data() {
    return {
      formData: {
        startTime: "", //起保日期
        endTime: "", //终保日期
        holderName: "", //投保人姓名
        holderUniteCode: "", //投保人统一社会信用代码
        holderAddress: "", //投保人联系地址
        holderPersonName: "", //投保人联系人姓名
        holderPhone: "", //投保人联系方式
        holderLastyearEmployeeSum: "", //投保人上年末员工总数
        holderLastyearSaleIncome: "", //投保人上年度营收收入
        holderLastyearMoneySum: "", //投保人上年末资产总额
        holderBusinessMainType: "", //投保人主营业务类型
        insuredName: "", //被保人姓名
        insuredUniteCode: "", //被保人统一社会信用代码
        insuredAddress: "", //被保人联系地址
        insuredPersonName: "", //被保人联系人姓名
        insuredPhone: "", //被保人联系方式
        rooms: "", //房间数
        insuredLastyearEmployeeSum: "", //被保人上年末员工总数
        insuredLastyearSaleIncome: "", //被保人上年度营收收入
        insuredLastyearMoneySum: "", //被保人上年末资产总额
        insuredBusinessMainType: "", //被保人主营业务类型
        employeePlan: "", //雇主责任方案
        plan: "", //方案
        price: "", //价格、保费
        id: "", //订单id
      },
      isScheme: false, //是否选择雇主责任险
      schemeList: [
        //雇主责任险的方案列表
        {
          name: "方案一",
          value: "142",
        },
        {
          name: "方案二",
          value: "266",
        },
      ],
      schemeActive: 0, //雇主责任险的方案下标值
      isSwitch: false, //开关值
      isDate: false, //是否展示日期组件
      typeDate: "", //日期组件类型(投保人、被保人)
      isCity: false, //是否展示城市组件
      typeCity: "", //城市组件类型(投保人、被保人)
      holderCityName: "", //投保人城市
      insuredCityName: "", //被保人城市
      isBusiness: false, //是否展示主营业务类型组件
      typeBusiness: "", //营业务类型组件类型(投保人、被保人)
      showPopup: false, //投保成功弹窗
      holderAddress: "", //投保人详细地址
      insuredAddress: "", //被保人详细地址
      checkDate: "", //选中日期组件的值
      cityIdHolder: "", //选中投保人城市组件的值
      cityIdInsured: "", //选中被保人城市组件的值
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "employeeList") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    document.title = "填写资料";
    this.limit();
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.isFirstEnter = false;
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  mounted() {},
  methods: {
    handleTouchStart() {},
    //我知道了
    back() {
      this.showPopup = false;
      this.$router.go(-1);
    },
    //点击开关
    changeSwitch(val) {
      if (val) {
        this.formData.insuredName = this.formData.holderName;
        this.formData.insuredUniteCode = this.formData.holderUniteCode;
        this.insuredCityName = this.holderCityName;
        this.insuredAddress = this.holderAddress;
        this.formData.insuredAddress = this.formData.holderAddress;
        this.formData.insuredPersonName = this.formData.holderPersonName;
        this.formData.insuredPhone = this.formData.holderPhone;
        this.formData.insuredLastyearEmployeeSum =
          this.formData.holderLastyearEmployeeSum;
        this.formData.insuredLastyearSaleIncome =
          this.formData.holderLastyearSaleIncome;
        this.formData.insuredBusinessMainType =
          this.formData.holderBusinessMainType;
        this.formData.insuredLastyearMoneySum =
          this.formData.holderLastyearMoneySum;
        this.formData.rooms = "";
      } else {
        this.formData.insuredName = "";
        this.formData.insuredUniteCode = "";
        this.insuredCityName = "";
        this.insuredAddress = "";
        this.formData.insuredAddress = "";
        this.formData.insuredPersonName = "";
        this.formData.insuredPhone = "";
        this.formData.insuredLastyearEmployeeSum = "";
        this.formData.insuredLastyearSaleIncome = "";
        this.formData.insuredBusinessMainType = "";
        this.formData.insuredLastyearMoneySum = "";
        this.formData.rooms = "";
      }
    },
    //是否选择雇主责任险
    changeIsScheme() {
      this.isScheme = !this.isScheme;
      this.limit();
    },
    //计算价格
    limit() {
      this.formData.price = this.$route.query.price;
      if (this.isScheme) {
        if (this.schemeActive == 0) {
          if (sessionStorage.getItem("employSum")) {
            this.formData.price =
              parseFloat(this.formData.price) +
              142 * parseInt(sessionStorage.getItem("employSum"));
            console.log(this.formData.price);
          }
        } else {
          if (sessionStorage.getItem("employSum")) {
            this.formData.price =
              parseFloat(this.formData.price) +
              266 * parseInt(sessionStorage.getItem("employSum"));
            console.log(this.formData.price);
          }
        }
      }
    },
    //选择主营业务类型组件回调
    finshBusiness(show, value, type) {
      if (type == "holder") {
        //投保人主营业务类型回调值
        this.formData.holderBusinessMainType = value;
      } else {
        //被保人主营业务类型回调值
        this.formData.insuredBusinessMainType = value;
      }
      this.isBusiness = show;
    },
    //关闭主营业务类型组件
    closeBusiness(val) {
      this.isBusiness = val;
    },
    //点击展示主营业务类型组件
    changeBusiness(type) {
      this.typeBusiness = type;
      this.isBusiness = true;
    },
    //选择城市组件回调
    finishCity(show, value, type, cityId) {
      if (type == "holder") {
        //投保人城市回调值
        this.holderCityName = value;
        this.cityIdHolder = cityId;
      } else {
        //被保人城市回调值
        this.insuredCityName = value;
        this.cityIdInsured = cityId;
      }

      this.isCity = show;
    },
    //关闭城市组件
    closeCity(val) {
      this.isCity = val;
    },
    //点击展示城市组件
    changeCity(type) {
      this.typeCity = type;
      this.isCity = true;
    },
    //点击展示日期组件
    changeDate(type) {
      this.typeDate = type;
      this.checkDate = this.formData.startTime;
      this.isDate = true;
    },
    //关闭日期弹窗
    cancelDate(type) {
      this.isDate = false;
    },
    //回调日期
    callbackCertDate(type, value) {
      if (type == "start") {
        //起保日期
        this.formData.startTime = value;
      } else if (type == "end") {
        //终保日期
        this.formData.endTime = value;
      }
      var oneYear = this.oneYearPast(new Date(this.formData.startTime));
      this.formData.endTime = oneYear;
    },
    //获取一年后前一天时间
    oneYearPast(time) {
      //var time=new Date();
      var year;
      if (time.getMonth() + 1 == 1 && time.getDate() == 1) {
        year = time.getFullYear();
      } else {
        year = time.getFullYear() + 1;
      }
      var month = time.getMonth() + 1;
      var day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }

      if (day > 1) {
        day = day - 1;
      } else {
        month = month - 1;
        if (month < 10) {
          month = "0" + month;
        }
        if (month == 0) {
          month = 12;
        }
        day = new Date(year, month, 0).getDate();
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (day == 0) {
        day = 12;
      }

      var v2 = year + "-" + month + "-" + day;
      return v2;
    },

    //填写雇员清单
    employee() {
      if (this.formData.startTime) {
        sessionStorage.setItem('startTime',this.formData.startTime);
        sessionStorage.setItem('endTime',this.formData.endTime);
        this.$router.push({
          path: "employeeList",
        });
      } else {
        this.$toast('请先选择起保日期和终保日期');
      }
    },
    //选择雇主责任险
    changeScheme(item, index) {
      this.schemeActive = index;
      this.limit();
    },
    //投保
    submit() {
      if (this.formData.startTime == "") {
        this.$toast("请选择起保日期");
      } else if (this.formData.holderName == "") {
        this.$toast("请填写投保人名称");
      } else if (this.formData.holderUniteCode == "") {
        this.$toast("请填写投保人统一信用代码");
      } else if (this.holderCityName == "") {
        this.$toast("请选择投保人地址");
      } else if (this.holderAddress == "") {
        this.$toast("请填写投保人详细地址");
      } else if (this.formData.holderPersonName == "") {
        this.$toast("请填写投保人联系人");
      } else if (this.formData.holderPhone == "") {
        this.$toast("请填写投保人联系方式");
      } else if (!validPhone(this.formData.holderPhone)) {
        this.$toast("投保人联系方式格式不正确");
      } else if (this.formData.holderLastyearEmployeeSum == "") {
        this.$toast("请填写投保人上年末员工总数");
      } else if (this.formData.holderLastyearSaleIncome == "") {
        this.$toast("请填写投保人上年度营业收入");
      } else if (this.formData.holderLastyearMoneySum == "") {
        this.$toast("请填写投保人上年末资产总额");
      } else if (this.formData.holderBusinessMainType == "") {
        this.$toast("请选择投保人主营业务类型");
      } else if (this.formData.insuredName == "") {
        this.$toast("请填写被保人名称");
      } else if (this.formData.insuredUniteCode == "") {
        this.$toast("请填写被保人统一信用代码");
      } else if (this.insuredCityName == "") {
        this.$toast("请选择被保人地址");
      } else if (this.insuredAddress == "") {
        this.$toast("请填写被保人详细地址");
      } else if (this.formData.insuredPersonName == "") {
        this.$toast("请填写被保人联系人");
      } else if (this.formData.insuredPhone == "") {
        this.$toast("请填写被保人联系方式");
      } else if (!validPhone(this.formData.insuredPhone)) {
        this.$toast("被保人联系方式格式不正确");
      } else if (this.formData.rooms == "") {
        this.$toast("请填写被保人房间数");
      } else if (this.formData.rooms == 0) {
        this.$toast("被保人房间数不能为0");
      } else if (this.formData.insuredLastyearEmployeeSum == "") {
        this.$toast("请填写被保人上年末员工总数");
      } else if (this.formData.insuredLastyearSaleIncome == "") {
        this.$toast("请填写被保人上年度营业收入");
      } else if (this.formData.insuredLastyearMoneySum == "") {
        this.$toast("请填写被保人上年末资产总额");
      } else if (this.formData.insuredBusinessMainType == "") {
        this.$toast("请选择被保人主营业务类型");
      }
      // else if (this.isScheme) {
      //   if (sessionStorage.getItem("employSum")) {
      //     this.insure();
      //   } else {
      //     this.$toast("请填写雇员清单");
      //   }
      // }
      else {
        this.insure();
      }
    },
    //投保接口
    insure() {
      this.formData.plan = parseInt(this.$route.query.plan);
      this.formData.employeePlan = parseInt(this.schemeActive) + 1;
      this.formData.holderAddress = `${this.holderCityName},${this.holderAddress}`;
      this.formData.insuredAddress = `${this.insuredCityName},${this.insuredAddress}`;
      this.formData.id = sessionStorage.getItem("orderId");
      this.$toast.loading({
        message: "投保中...",
        forbidClick: true,
        duration: 0,
      });
      insure(this.formData).then((res) => {
        if (res) {
          this.showPopup = true;
          sessionStorage.clear();
        }
        this.$toast.clear();
      });
    },
    //校验姓名和身份证是否匹配
    async esignComparison(params, type) {
      await esignComparison(params).then((res) => {
        if (res.result != null && type == "Applicant") {
          this.isApplicant = true;
        }
        if (res.result != null && type == "Recognizee") {
          this.isRecognizee = true;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
input[disabled] {
  color: #999 !important;
  opacity: 1;
  -webkit-text-fill-color: #999 !important;
}
/deep/ .van-popup--center {
  border-radius: 10px;
}
/deep/ .van-popup {
  overflow-y: visible;
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 236px;
  height: 236px;
  img {
    width: 126px;
    margin-top: -30px;
  }
  .tit {
    font-size: 17px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-top: 10px;
  }
  .btn {
    width: 116px;
    height: 45px;
    border-radius: 24px;
    border: 1px solid #14aaf9;
    font-size: 15px;
    font-weight: bold;
    color: #14aaf9;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
  }
}
.busines {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.employee {
  padding: 26px 0 20px;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    text-decoration: underline;
  }
}
.schemeList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  .item {
    width: 167px;
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: 100%;
    p:first-child {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      margin-top: 10px;
    }
    p:last-child {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      margin-top: 26px;
    }
  }
  .active {
    background: url("../assets/detail/scheme-check.png") no-repeat;
    p:first-child {
      color: #ffffff;
    }
    p:last-child {
      color: $primary-color;
    }
  }
  .no-active {
    background: url("../assets/detail/scheme.png") no-repeat;
    p:first-child {
      color: #909090;
    }
    p:last-child {
      color: #909090;
    }
  }
}
.verificationCode {
  width: 100px;
  height: 30px;
  background: $primary-color;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verificationCodeTime {
  width: 100px;
  height: 30px;
  background: #e5e5e5;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input:disabled {
  background-color: #fff; //修改默认灰色样式
  color: #000;
  opacity: 1; //默认的不透明级别为0.3
  -webkit-text-fill-color: #000; //字体颜色安卓与IOS适配
  -webkit-opacity: 1; ///不透明级别安卓与IOS适配
}
// input{
//   color: #000 !important;
// }

.insure {
  padding: 10px;
  /deep/ .van-dialog__confirm {
    .van-button__text {
      color: $primary-color;
    }
  }
  /deep/ .van-cell {
    padding: 5px 0;
  }
  /deep/ .van-cell__title {
    width: 90px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 44px;
    margin-right: 0;
  }

  /deep/ .van-cell::after {
    border-bottom: 0;
  }

  /deep/ .van-cell__value {
    width: 100%;
    flex: 1;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 400;
    .van-field__body {
      height: 100%;
    }
    input::-webkit-input-placeholder {
      color: #999;
    }
  }
  /deep/ .van-dialog {
    .van-cell {
      padding: 20px;
    }
  }
  .applicantContent {
    padding-bottom: 100px;
    .applicant-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .left {
        display: flex;
        align-items: center;
      }
      .right {
        img {
          width: 19px;
        }
      }
      .titleNum {
        width: 18px;
        height: 18px;
        background: $primary-color;
        border-radius: 2px;
        font-size: 11px;
        font-weight: 500;
        color: #ffffff;
        line-height: 11px;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .titleName {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-left: 5px;
      }
    }
    .form {
      width: 100%;
      margin-top: 5px;
      .uni-form-item {
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        .unit {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          text-align: right;
          margin-right: 8px;
        }
        img {
          width: 10px;
          margin-left: 10px;
        }
        .btnCode {
          width: 114px;
          height: 30px;
          background: $primary-color;
          border-radius: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
        }
        .tit {
          width: 120px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 44px;
        }
        .uni-input {
          width: 100%;
          flex: 1;
          margin-left: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          padding: 0;
          .input {
            color: #000;
          }
          .inputno {
            color: #999;
          }
        }
        .arrow {
          width: 10px;
          font-size: 16px;
          margin-right: 17px;
        }
      }
    }
  }
}
.beneficiaryType {
  display: flex;
  margin-top: 34px;

  text {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 72px;
    border-radius: 42px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;

    margin-right: 40px;
  }

  .beneficiary-active {
    background: $primary-color;
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .beneficiaryTit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    .line1 {
      float: left;
    }
    .line2 {
      float: left;
    }
  }
  .delete {
    font-size: 14px;
    color: #999999;
    line-height: 5px;
  }
}
.add {
  width: 124px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid $primary-color;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
  line-height: 36px;
  margin: 0 auto;
  margin-top: 20px;
}
.footer {
  // width: 100%;
  // height: 80px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // z-index: 100;
  // background-color: #ffffff;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding-bottom: 15px;
  padding-top: 15px;
  .clauseBox {
    padding: 10px 10px;
    /deep/ .van-checkbox__icon {
      margin-top: 3px;
    }
    .clause {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      div {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding: 0 16px 0 0;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        font-weight: 400;
        color: $primary-color;
      }
    }
  }

  .prcieContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
      line-height: 25px;
      padding-left: 10px;
      span {
        font-size: 22px;
        line-height: 21px;
      }
    }
    .btn {
      margin-right: 10px;
      width: 116px;
      height: 45px;
      line-height: 45px;
      background: $primary-color;
      border-radius: 48px;
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.beneficiaryType {
  display: flex;
  margin-top: 34px;
  div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    margin-right: 10px;
  }

  .beneficiary-active {
    background: $primary-color;
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.btns {
  display: flex;
  align-items: center;
  margin-top: 15px;

  div {
    height: 40px;
    padding: 5px 5px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
  }

  .active {
    background: $primary-color;
    color: #ffffff;
    padding: 0 25px;
  }

  .no-active {
    padding: 0 25px;
    border: 2px solid $primary-color;
    box-sizing: border-box;
    color: $primary-color;
  }
}
</style>
