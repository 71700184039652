<template>
  <div>
    <van-popup
      v-model="show"
      position="right"
      :style="{ height: '100%', width: '85%' }"
      @click-overlay="onClose"
      @close="onClose"
    >
      <div class="detail">
        <div class="title">主营业务行业类型</div>
        <div class="msg">
          <van-checkbox-group v-model="result">
            <van-cell-group>
              <van-cell
                v-for="(item, index) in list"
                clickable
                :key="item"
                :title="
                  index + 1 > 9 ? `${index + 1}${item}` : `0${index + 1}${item}`
                "
                @click="toggle(index)"
              >
                <template #right-icon>
                  <van-checkbox :name="item" ref="checkboxes" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </div>
        <div class="btn" @click="onFinsh">确定</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    isShow: Boolean,
    typeBusiness: String,
    holderBusinessMainType: String,
    insuredBusinessMainType: String,
  },
  watch: {
    isShow(newVlue) {
      this.show = newVlue;
      if (this.typeBusiness == "holder") {
        if (this.holderBusinessMainType) {
          let arr = this.holderBusinessMainType.split(",");
          this.result = arr;
        } else {
          this.result = [];
        }
      } else {
        if (this.insuredBusinessMainType) {
          let arr = this.insuredBusinessMainType.split(",");
          this.result = arr;
        } else {
          this.result = [];
        }
      }
    },
  },
  data() {
    return {
      show: false,
      list: [
        "采矿业",
        "建筑业",
        "房地产业",
        "农、林、牧、渔业",
        "住宿和餐饮业",
        "金融业",
        "制造业",
        "国际组织",
        "租赁和商务服务化",
        "批发和零售业",
        "教育",
        "居民服务和其他服务业",
        "卫生、社会保障和社会福利业",
        "电力、燃气及水的生产和供应业",
        "交通运输、仓储和邮政业",
        "水利、环境和公共设施管理业",
        "科学研究、技术服务和地质勘查业",
        "公共管理与社会组织",
        "文化、体育和娱乐业",
        "信息传输、计算机服务和软件业",
      ],
      result: [],
    };
  },
  mounted() {},
  onLoad() {},
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    //关闭popup组件
    onClose() {
      this.show = false;
      this.$emit("onClose", this.show);
    },
    //选择类型
    onFinsh() {
      this.show = false;
      let str = this.result.toString();
      this.$emit("onFinsh", this.show, str, this.typeBusiness);
    },
  },
};
</script>


<style scoped lang="scss">
.detail {
  padding: 0 15px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  text-align: center;
  margin-top: 17px;
}
.msg {
  width: 100%;
  height: 95%;
  overflow: auto;
}
.btn {
  width: 116px;
  height: 45px;
  background: $primary-color;
  border-radius: 24px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px;
}
</style>
