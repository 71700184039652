<template>
  <div>
    <van-popup v-model="show" round position="bottom" @click-overlay="onClose">
      <van-cascader
        v-model="cascaderValue"
        title="请选择地址"
        :options="options"
        @close="onClose"
        @finish="onFinish"
        :field-names="fieldNames"
        active-color="#1B25FE"
      />
    </van-popup>
  </div>
</template>
<script>
import { selectByArea } from "../utils/api";
export default {
  components: {},
  props: {
    isShow: Boolean,
    typeCity: String,
    cityIdHolder: String,
    cityIdInsured: String,
  },
  watch: {
    isShow(newVlue) {
      this.show = newVlue;
      if (this.typeCity == "holder") {
        if (this.cityIdHolder) {
          this.cascaderValue = this.cityIdHolder;
        } else {
          this.cascaderValue = "";
        }
      } else {
        if (this.cityIdInsured) {
          this.cascaderValue = this.cityIdInsured;
        } else {
          this.cascaderValue = "";
        }
      }
    },
  },
  data() {
    return {
      show: false,
      cascaderValue: "", //选中城市的值
      fieldNames: {
        text: "name",
        value: "id",
        children: "areaList",
      },
      options: [],
    };
  },
  mounted() {
    this.selectByArea();
  },
  methods: {
    //获取所有城市列表
    selectByArea() {
      selectByArea().then((res) => {
        console.log(res);
        res.result.map((item) => {
          if (item.areaList.length == 0) {
            delete item.areaList;
          } else {
            item.areaList.map((item1) => {
              if (item1.areaList.length == 0) {
                delete item1.areaList;
              } else {
                item1.areaList.map((item2) => {
                  if (item2.areaList.length == 0) {
                    delete item2.areaList;
                  } else {
                    item2.areaList.map((item3) => {
                      if (item3.areaList.length == 0) {
                        delete item3.areaList;
                      }
                    });
                  }
                });
              }
            });
          }
        });

        this.options = res.result;
      });
    },
    //关闭城市组件
    onClose() {
      this.show = false;
      this.$emit("onClose", this.show);
    },
    onFinish({ selectedOptions }) {
      console.log(this.cascaderValue);
      console.log(selectedOptions);
      this.show = false;
      let checkValue = selectedOptions.map((option) => option.name).join(",");
      this.$emit(
        "onFinish",
        this.show,
        checkValue,
        this.typeCity,
        this.cascaderValue
      );
    },
  },
};
</script>


<style scoped lang="scss">
.content {
  padding: 16px 16px 16px;
}
</style>
